import TypeRequestedFlow from "../../static-data/TypeRequestedFlow";
import FormRequestAction from "../../static-data/FormRequestAction";
import ServiceRequestType from "../../static-data/ServiceRequestType";
import axios from "../http-client/axios";
import requestBuilder from "../request-builder/request-builder";
import { SCORPIONS_URL } from "../urls/base-urls";
import { METALICA_URL } from "../urls/base-urls";
import mapperToAddressRequest from "./address-mapper";

//  eslint-disable-next-line no-unused-vars
export async function getAddresses(crm) {
  const resp = await axios({
    url: `${SCORPIONS_URL}/v2/address?crm=${crm}`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });

  return resp.data;
}
export async function jmjUpdateAddress(formData, attachments) {
  var originalData = mapperToAddressRequest(
    formData.originalData,
    FormRequestAction.update
  );

  var changedData = mapperToAddressRequest(formData, FormRequestAction.update);

  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      originalData,
      TypeRequestedFlow.update,
      attachments,
      ServiceRequestType.PERSON_DATA_ADDRESS,
      null,
      changedData
    )
  );
  return resp.data;
}
export async function updateAddress(formData) {
    const bodyToRequest = {
      ...formData,
      "typeAddress": formData.originalData.typeAddress,
      "newTypeAddress": formData.typeAddress,
      "personCorrespondence": formData.personCorrespondence ? 'C' : ''
  }
  const resp = await axios.put(
    `${SCORPIONS_URL}/address`, bodyToRequest);
  return resp.data;
}

export async function addAddress(formData, attachments) {
  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      mapperToAddressRequest(formData, FormRequestAction.insert),
      TypeRequestedFlow.save,
      attachments,
      ServiceRequestType.PERSON_DATA_ADDRESS
    )
  );
  return resp.data;
}

export async function deleteAddress(formData) {
  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      mapperToAddressRequest(formData, FormRequestAction.delete),
      TypeRequestedFlow.delete,
      [],
      ServiceRequestType.PERSON_DATA_ADDRESS
    )
  );
  return resp.data;
}

//  eslint-disable-next-line no-unused-vars
export async function getTypesAddress() {
  const resp = await axios({
    url: `${SCORPIONS_URL}/types-address`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });

  return resp.data;
}
export async function updateAddressPublicizeOption(request) {
  await axios({
    url: `${SCORPIONS_URL}/notification/doctor-place-service`,
    method: "post",
    data: request,
  });
}
