import { formatCep } from "../../utils/format/formtUtils";

export default function mapperToAddressRequest(formData, requestAction) {
  return {
    "Ação da solicitação": requestAction,
    "Tipo do endereço": formData.typeAddress,
    CEP: formatCep(formData.cep),
    Rua: formData.publicPlace,
    Número: formData.number,
    Complemento: formData.complement,
    Bairro: formData.district,
    Cidade: formData.city,
    Estado: formData.uf
  };
}
