<template>
  <div>
    <b-card class="custom-card my-4" border-variant="light">
      <p
        class="P21"
        v-if="this.form.typeAddressLabel"
        v-text="this.form.typeAddressLabel"
      ></p>
      <p class="P21" v-if="!this.form.typeAddressLabel">NOVO ENDEREÇO</p>
      <b-form id="alter-form" class="alter-form">
        <b-form-row>
          <b-col>
            Tipo
          </b-col>
          <b-col>
            <b-form-select
              :disabled="!!$props.loadedFormData.typeAddress"
              v-model.lazy.trim="form.typeAddress"
              :state="validateField('typeAddress')"
              :options="typeAddresses"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            CEP
          </b-col>
          <b-col>
            <CustomMaskInput
              :initialValue="form.cep"
              :options="cepOptions"
              :state="validateFieldCEP('cep')"
              placeholder="Digite"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            Logradouro
          </b-col>
          <b-col>
            <b-form-input
              v-model.lazy.trim="form.publicPlace"
              :state="validateField('publicPlace')"
              placeholder="Digite"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            Número
          </b-col>
          <b-col>
            <b-form-input
              v-model.lazy.trim="form.number"
              type="number"
              :state="validateField('number')"
              placeholder="Digite"
            />
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            Complemento
          </b-col>
          <b-col>
            <b-form-input
              v-model.lazy.trim="form.complement"
              placeholder="Digite"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            Bairro
          </b-col>
          <b-col>
            <b-form-input
              v-model.lazy.trim="form.district"
              :state="validateField('district')"
              placeholder="Digite"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            Cidade
          </b-col>
          <b-col>
            <b-form-input
              v-model.lazy.trim="form.city"
              :state="validateField('city')"
              placeholder="Digite"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            UF
          </b-col>
          <b-col>
            <b-form-input
              v-model.lazy.trim="form.uf"
              :state="validateField('uf')"
              placeholder="Digite"
            />
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <UploadFile
              @updateFile="eventToUpdateFile"
              :multiple="true"
              :description="'Anexar documento para comprovação'"
              :msgErro="'É necessário inserir um anexo com a comprovação.'"
              :state="validateFieldFile('files')"
              :required="true"
            />
          </b-col>
        </b-form-row>
      </b-form>
    </b-card>

    <div class="my-3">
      <p class="p4 color-green-2" v-if="!formNotTouched()">
        As alterações que você está prestes a realizar, terão
        <strong>impacto direto no eSocial</strong>, assim como em seus
        <strong>dados do plano de saúde</strong>, ao salvar, algumas alterações
        acontecerão de forma automática em nossa base de dados, mas fique
        tranquilo(a),
        <strong
          >você poderá alterar essas informações novamente a qualquer
          momento</strong
        >.
      </p>
    </div>
    <div class="d-flex custom-check" v-if="!formNotTouched()">
      <b-form-checkbox size="lg" v-model="agree"
        ><p class="p4">
          Declaro que estou ciente das regras referentes a alteração de
          informações
        </p></b-form-checkbox
      >
    </div>

    <b-row class="my-5">
      <b-button
        variant="outline-primary"
        @click="this.onClosetHandler"
        class="ml-4"
      >
        Cancelar
      </b-button>

      <b-button
        form="alter-form"
        type="button"
        class="ml-4"
        variant="primary"
        id="submitButtonText"
        :disabled="!agree || this.$store.getters.isAdmin"
        @click="this.onSubmitHandler"
      >
        Salvar
      </b-button>
    </b-row>
  </div>
</template>

<script>
import UploadFile from "../../../../../components/upload-file/UploadFile.vue";
import CustomMaskInput from "../../../../../components/forms/custom-mask-input/CustomMaskInput.vue";

const DefaultForm = {
  typeAddress: null,
  typeAddressLabel: null,
  codePerson: null,
  addressId: null,
  cep: null,
  publicPlace: null,
  number: null,
  complement: null,
  district: null,
  city: null,
  uf: null,
  files: null,
  personCorrespondence: false,
  originalData: null,
};

export default {
  props: [
    "loadedFormData",
    "loadedFormDataOptions",
    "newRegister",
    "onClose",
    "typeAddressesOptions",
    "onSubmit",
  ],
  components: { CustomMaskInput, UploadFile },
  setup() {
    return {};
  },
  mounted() {
    this.initForm();
  },
  data() {
    return {
      formSubmited: false,
      form: {
        ...DefaultForm,
      },
      validations: {},
      typeAddresses: [],
      cepOptions: {
        numericOnly: true,
        delimiters: ["-"],
        blocks: [5, 3],
        onValueChanged: this.onChangeCep,
      },
      selectedOption: null,
      selectOptions: [],
      agree: false,
    };
  },
  methods: {
    formNotTouched() {
      return (
        this.form.typeAddress == this.form.originalData?.typeAddress &&
        this.form.codePerson == this.form.originalData?.codePerson &&
        this.form.addressId == this.form.originalData?.addressId &&
        this.form.cep == this.form.originalData?.cep &&
        this.form.publicPlace == this.form.originalData?.publicPlace &&
        this.form.number == this.form.originalData?.number &&
        this.form.complement == this.form.originalData?.complement &&
        this.form.district == this.form.originalData?.district &&
        this.form.city == this.form.originalData?.city &&
        this.form.uf == this.form.originalData?.uf &&
        this.form.personCorrespondence ==
          this.form.originalData?.personCorrespondence
      );
    },
    onClosetHandler() {
      if (this.$props.onClose) {
        this.$props.onClose();
      }
    },
    initForm() {
      const loadedFormData = this.$props.loadedFormData;
      if (loadedFormData) {
        const personCorrespondence =
          loadedFormData.personCorrespondence &&
          loadedFormData.personCorrespondence.toLowerCase() == "sim";

        this.form = {
          ...this.form,
          ...loadedFormData,
          personCorrespondence: personCorrespondence,
          typeAddress: loadedFormData.addressId,
          typeAddressLabel: loadedFormData.typeAddress,
        };
        this.form.originalData = { ...this.form };
        this.typeAddresses = this.buildtypeAddressesOptions(
          this.$props.typeAddressesOptions
        );
      }
    },
    validateCheckBox(name) {
      this.validations[name] = true;
      if (!this.formSubmited) return null;
      return true;
    },
    showForm() {
      return this.selectedOption || this.$props.newRegister;
    },
    buildtypeAddressesOptions(typesAddressOptions) {
      return [
        { value: null, text: "Selecione", disabled: true },
        ...typesAddressOptions.map((option) => ({
          value: option.addressId,
          text: option.description,
        })),
      ];
    },
    onChangeCep(e) {
      this.form.cep = e.target.rawValue;
    },
    validateFieldCEP(name) {
      let value = this.form[name];
      let validation = true;
      validation = !!value;
      validation = validation && value.length == 8;

      this.validations[name] = validation;
      if (!this.formSubmited) return null;

      return validation;
    },
    validateField(name) {
      let value = this.form[name];

      let validation = !!value;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;

      return !!value;
    },
    validateFieldFile(name) {
      let value = this.form[name];
      const validation = !!value && value.length > 0;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;
      return validation;
    },
    requiredFieldsFilled() {
      return Object.values(this.validations).every(
        (fieldValue) => fieldValue == true
      );
    },
    eventToUpdateFile(files) {
      this.form.files = files;
    },
    onSubmitHandler() {
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        this.$props.onSubmit(this.form);
      }
    },
    onDelete() {
      return this.selectedOption;
    },
  },
};
</script>

<style lang="scss" scoped>
div.form-row {
  padding: 0.5rem;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: none;
  }
}

input.form-control {
  text-align: right;
  border: 1px solid transparent;
}

select.custom-select {
  text-align: right;
  border: 1px solid transparent;
}

.P21 {
  color: var(--primary);
  font-size: multiplier-default-font-size(3); // 16
  margin-left: 1.5rem;
}

.custom-check {
  margin-top: 8px;
}

.check-declaration {
  padding-top: 0px;
}
.p4 {
  font-size: 1.6rem;
}
</style>
