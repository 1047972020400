<template>
  <div>
    <b-row class="justify-content-center mb-4">
      <p class="p4 color-green-2">
        Confira seus dados e, caso haja divergências, solicite alteração
        cadastral a qualquer momento.
      </p>
    </b-row>
    <div v-show="!this.loading && !this.inEditing">
      <AccordionList
        titleKeyName="typeAddress"
        :items="this.tableItems"
        :itemsFields="this.tableFields"
        :onEdit="this.onEdit"
        :onDelete="this.onDelete"
        msgToggleEdit="Editar Endereço"
        msgToggleDelete="Excluir Endereço"
      />
    </div>
    <div v-show="this.loading && !this.inEditing">
      <b-card>
        <b-skeleton type="AccordionList" width="100%"></b-skeleton>
        <b-skeleton-table
          :rows="tableFields.length"
          :columns="2"
          :hide-header="true"
        ></b-skeleton-table>
      </b-card>
    </div>

    <div class="mt-5" v-show="!this.loading && this.inEditing">
      <NewAddressForm
        v-if="this.inEditing"
        :onSubmit="this.onSubmitEdit"
        :onClose="this.onClose"
        :loadedFormDataOptions="this.tableItems"
        :typeAddressesOptions="this.typeAddressesOptions"
        :loadedFormData="this.loadedFormData"
      />
    </div>

    <div class="mt-5" v-show="!this.loading && !this.inEditing">
      <b-button
        class="my-3 mr-3"
        variant="outline-primary"
        @click="this.onClickAddNewAddress"
        v-show="this.typeAddressesOptions.length > 0"
      >
        Adicionar endereço
      </b-button>
    </div>
    <success-modal
      ref="success-modal"
      :description="this.msgSuccess"
      buttonText="Ok, entendi!"
      :protocol="this.protocol"
    />
    <attention-modal
      modalId="delete-attention-modal"
      title="Atenção!"
      submitButtonText="Sim"
      cancelButtonText="Não"
      ref="attention-modal"
      :onAction="this.onDeleteComfirm"
    >
      <template slot="description">
        <p class="p1 mb-5">Tem certeza que deseja excluir este endereço?</p>
      </template>
    </attention-modal>
  </div>
</template>

<script>
import AccordionList from "../../../../components/accordion-list/AccordionList.vue";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import AttentionModal from "../../../../components/base-modals/AttentionModal.vue";

import {
  deleteAddress,
  getAddresses,
  getTypesAddress,
  updateAddress,
  jmjUpdateAddress,
  addAddress,
} from "../../../../services/addresses/addressesService";
import NewAddressForm from "../forms/addresses/NewAddressForm.vue";
import { inject } from "@vue/composition-api";

const tableFields = [
  { key: "typeAddress", label: "Tipo", tdClass: "text-right" },
  { key: "cep", label: "CEP", tdClass: "text-right" },
  { key: "publicPlace", label: "LOGRADOURO", tdClass: "text-right" },
  { key: "number", label: "NÚMERO", tdClass: "text-right" },
  { key: "complement", label: "COMPLEMENTO", tdClass: "text-right" },
  { key: "district", label: "BAIRRO", tdClass: "text-right" },
  { key: "city", label: "CIDADE", tdClass: "text-right" },
  { key: "uf", label: "ESTADO", tdClass: "text-right" },
  {
    key: "personCorrespondence",
    label: "RECEBIMENTO DE CORRESPONDÊNCIAS",
    tdClass: "text-right",
  },
];

const AddressInformation = {
  name: "address-information",
  components: {
    AccordionList,
    SuccessModal,
    AttentionModal,
    NewAddressForm,
  },
  setup() {
    const globalLoading = inject("globalLoading");
    return { tableFields, globalLoading };
  },
  mounted() {
    this.getUserAddreses();
    this.geTypesAddreses();
  },
  data() {
    return {
      tableItems: [],
      loading: true,
      protocol: "",
      typeAddressesOptions: [],
      itemToDelete: null,
      inEditing: false,
      loadedFormData: null,
      msgSuccess: "",
    };
  },
  methods: {
    async onSubmitEdit(formData) {
      this.globalLoading = true;
      try {
        if (this.newRegister == true) {
          this.msgSuccess = "Novo endereço cadastrado.";
          this.protocol = await addAddress(formData, formData.files);
        } else {
          this.msgSuccess = "Sua informações foram alteradas.";
          this.protocol = await jmjUpdateAddress(formData, formData.files);
          await updateAddress(formData);
        }
        this.showSuccessModal();
        this.onClose();
        this.getUserAddreses();
        this.newRegister = false;
        this.globalLoading = false;
      } catch (err) {
        console.error(err);
        this.newRegister = false;
        this.globalLoading = false;
      }
    },
    onClose() {
      this.inEditing = false;
    },
    onEdit(item) {
      this.loadedFormData = item;
      this.inEditing = true;
    },
    async onDeleteComfirm(confirmed) {
      if (!confirmed) return;
      try {
        this.globalLoading = true;
        this.msgSuccess = "Endereço excluído!";
        this.protocol = await deleteAddress(this.itemToDelete);
        this.globalLoading = false;
        this.showSuccessModal();
      } catch (error) {
        this.globalLoading = false;
        console.error("deleteAddress", error);
      }
    },
    async onDelete(item) {
      this.itemToDelete = item;
      const modal = this.$refs["attention-modal"].$children[0];
      modal.show();
    },
    showSuccessModal() {
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();
    },
    async getUserAddreses() {
      try {
        const { crm } = this.$store.getters.getUserInformation;
        this.loading = true;
        this.tableItems = await getAddresses(crm);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("getInformation", error);
      }
    },
    async geTypesAddreses() {
      try {
        const addressesOptions = await getTypesAddress();
        this.typeAddressesOptions = addressesOptions;
      } catch (error) {
        console.error("getTypesAddress", error);
      }
    },
    async onClickAddNewAddress() {
      this.newRegister = true;
      this.loadedFormData = {};
      this.inEditing = true;
    },
  },
};

export default AddressInformation;
</script>

<style lang="scss">
.addresses-container td::before {
  text-align: left !important;
}

.addresses-container td:first-child {
  border-color: #eff2f5;
}
</style>
